import React, { useState } from 'react';
import './About.css';
import { Feature } from "../../components";

const About = () => {
    const [hoverButton, setHoverButton] = useState(false);
    
    return (
        <div className="portfolio__about section__margin" id="about">
            <div className="portfolio__about-feature">
                <Feature 
                    title="Who is Anna?" 
                    text="Anna is an UX developer based in Australia that has a passion for 
                    communicating thoughts and ideas visually. 
                    </n>Holding a Bachelor’s Degree in Digital Media, as well as a Master's Degree in Information Technology, 
                    Anna loves to combine the visual flair of a Graphic Designer with the programming methodology of a Developer 
                    to create visually stunning as well as meaningful pieces of inspiring works. 
                    </n>Having been exposed to a wide variety of design styles and a
                    handful of frontend programming frameworks and languages in the industry, she does not limit 
                    herself to any type and is always on the hunt to deliver something new.
                    </n>Currently, She is a part of the Griffith App Factory where 
                    she's had the pleasure to work on many different projects from mobile app, websites, to branding.
                    </n>Having a passion for game design and development as well, she works on visual novel game in her free time where she’s responsible 
                    for plot writing, character design, music composition all the way down to programming." />
            </div>
            <div className="portfolio__about-heading">
                <h1 className="gradient__text">The scope of her experience and explorations cover...</h1>
                {hoverButton
                    ? <a href="#projects" className="text-shadow-pop-top" onMouseLeave={() => setHoverButton(false)}>Explore her works</a>
                    : <a href="#projects" onMouseOver={() => setHoverButton(true)}>Explore her works</a>
                }
            </div>
            <div className="portfolio__about-container">
                <Feature 
                    title="Mobile App" 
                    text="Deliver mobile applications for both iOS and Android operating systems with seamless navigation and beautiful design. 
                    Expertise in cross-platform development using Xamarin.Forms and C#." />
                <Feature 
                    title="Web Development" 
                    text="Build simple, responsive and intuitive web solutions to meet business needs, with user experience in mind. 
                    Exposure to a variety of web technologies, with strong interest in React." />
                <Feature 
                    title="Game Development" 
                    text="Unleash creative vision to develop fun and interactive games to cater to anyone. 
                    Fluent in 2D game development using Unity and C#."/>
            </div>
        </div>
    );
};

export default About;