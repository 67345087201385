import React from 'react';
import { Skill } from "../../components";
import { FaGithub, FaUnity, SiJavascript, FaReact, FaBootstrap, FaCss3, FaHtml5, SiCsharp, SiXamarin, SiMysql, SiAdobephotoshop, SiAdobeillustrator, MdSettingsSuggest, SiTypescript, SiAdobexd, SiLaravel, SiPhp, SiWordpress, FaElementor, SiTailwindcss, SiSqlite } from "./imports";
import './Skills.css';

const skillsData = [
    {title: "HTML5", img: <FaHtml5 />},
    {title: "CSS", img: <FaCss3 />},
    {title: "JavaScript", img: <SiJavascript />},
    {title: "TypeScript", img: <SiTypescript />},
    {title: "BootStrap", img: <FaBootstrap />},
    {title: "TailWind", img: <SiTailwindcss />},
    {title: "React", img: <FaReact />},
    {title: "Laravel", img: <SiLaravel />},
    {title: "PHP", img: <SiPhp />},
    {title: "MySQL", img: <SiMysql />},
    {title: "Sqlite", img: <SiSqlite />},
    {title: "RESTful APIs", img: <MdSettingsSuggest />},
    {title: "Xamarin", img: <SiXamarin />},
    {title: "C#", img: <SiCsharp />},
    {title: "Unity", img: <FaUnity />},
    {title: "WordPress", img: <SiWordpress />},
    {title: "Elementor", img: <FaElementor />},
    {title: "Github", img: <FaGithub />},
    {title: "Adobe XD", img: <SiAdobexd />},
    {title: "Illustrator", img: <SiAdobeillustrator />},
    {title: "Photoshop", img: <SiAdobephotoshop />}
]

const Skills = () => {
    return (
        <div className="portfolio__skills section__padding" id="skills">
            <div className="portfolio__skills-heading">
                <h1 className="gradient__text">Skills & Tools</h1>
            </div>
            <div className="portfolio__skills-container">
                { skillsData.map((item, index) => (
                    <Skill image={item.img} title={item.title} key={item.title + index}/>
                ))}
            </div>
        </div>
    );
};

export default Skills;